import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../components/button';
import { Container } from '../components/container';
import { LocalizedLink } from '../components/localized-link';
import { useLangContext } from '../utils/lang';

// import { Link } from 'gatsby';
const ThankYouOrder = () => {
  const { lang } = useLangContext()
  const { t } = useTranslation()
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState({});
  const [orderIdFromData, setOrderIdFromData] = useState("");
  const [message, setMessage] = useState(null);

  async function getOrderParams(id) {
    await fetch(`${process.env.GATSBY_APP_API_URL}/check-online-payment?id=${id}`).then(res => res.json()).then(data => setOrderData(data));
  }

  useEffect(() => {
    let urlString = document.location.href
    let url = new URL(urlString)
    let id = url.searchParams.get("id")
    console.log("check-online-payment search param id", id);
    id && setOrderId(id);
  }, []);

  useEffect(() => {
    console.log("check-online-payment orderId", orderId);
    orderId !== "" && getOrderParams(orderId);
  }, [orderId]);

  useEffect(() => {
    console.log("check-online-payment data", orderData);
    if (Object.keys(orderData).length !== 0) {
      // cj.order
      setOrderIdFromData(orderData?.orderId);
      if (orderData?.payment_status && orderData?.payment_status !== "") {
        setMessage(orderData?.payment_status);
      } else if (orderData?.error && orderData?.error?.msg) {
        setMessage(orderData?.error?.msg);
      } else {
        setMessage("Sommething went wrong")
      }
      if (orderData?.cjevent && orderData.cjevent !== "") {
        window.cj.order.orderId = orderData.orderId;
        window.cj.order.userId = orderData.userId;
        window.cj.order.amount = orderData.amount;
        window.cj.order.cjevent = orderData.cjevent;
        window.cj.order.coupon = orderData.coupon;
        console.log("gonna redirect to affiliate TY")
        window.location.href = `/thank-you-affiliate/?status=${orderData.payment_status}&orderId=${orderData.orderId}&userId=${orderData.userId}&amount=${orderData.amount}&cjevent=${orderData.cjevent}&coupon=${orderData.coupon}`
      }
    }
  }, [orderData]);

  useEffect(() => {
    console.log("check-online-payment orderIdFromData", orderIdFromData);
    console.log("check-online-payment message", message);
  }, [orderIdFromData, message]);

  return (
    <>
      <Container className="text-center">
        <h1 className="text-center" style={{ marginTop: "100px" }}>
          {t("forms.TY.titlePart1")}
          <br />
          <span style={{ color: `var(--color-green)` }}>
            {" "}
            {t("forms.TY.titlePart2")}
          </span>
        </h1>
        <h2>{Object.keys(orderData).length !== 0 && orderData?.payment_status}</h2>
        <Button type="primary">
          <LocalizedLink to="/"> {t("forms.TY.linkBack")}</LocalizedLink>
        </Button>
        <div style={{ marginBottom: "100px" }} />
      </Container>
    </>
  )
}

export default ThankYouOrder
